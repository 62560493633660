
import Android from "../logos/Android";
import Java from "../logos/Java";

export default function HighLow() {
    return (
        <>
            <img src="/android.png" className="absolute w-full h-full object-contain rounded-lg group-hover:brightness-40 transition duration-300 transform" alt="" />
            <div className="hidden absolute bottom-0 right-0 p-5 gap-6 group-hover:flex">
                <Android/>
                <Java/>
            </div>
            <div className="absolute hidden whitespace-pre-line bottom-0 left-0 p-5 gap-6 font-serif text-2xl text-left text-white  w-2/5 group-hover:flex">
                Sneakers Shop Mobile App Using Android and Java that allow buy sneakers.
            </div>
            <div className="absolute hidden inset-0 items-center justify-center font-serif text-8xl text-white group-hover:flex">
                HighLow
            </div>
        </>
    )
}