import CSharp from "../logos/CSharp";
import Unity from "../logos/Unity";

export default function Game() {
    return (
        <>
            <img src="/game_img.png" className="absolute w-full h-full object-fill rounded-lg group-hover:brightness-40 transition duration-300 transform " alt="" />
            <div className="hidden absolute bottom-0 right-0 p-5 gap-6 group-hover:flex">
                <Unity />
                <CSharp />
            </div>
            <div className="absolute hidden whitespace-pre-line bottom-0 left-0 p-5 gap-6 font-serif text-2xl text-left text-white w-2/5 group-hover:flex">
                RPG game using C# and Unity for new assistant in Bina Nusantara University that offers a unique experience with a combination of maze and monsters.
            </div>
            <div className="absolute hidden inset-0 items-center justify-center font-serif text-8xl text-white group-hover:flex">
                monster FunTer
            </div>
        </>
    )
}