import { forwardRef } from "react";


const Contact = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <>
            <div className="scroll-snap-align-start" ref={ref}>
                <div className="pt-20 first-letter:relative flex-grow min-w-screen min-h-screen box-border flex text-white ">
                    <div className="p-10 box-border w-full min-h-full flex justify-center items-center gap-10">
                        <div className="h-full w-2/3 p-10 flex flex-col justify-center items-center bg-blur-xl shadow-white-box bg-white text-white font-serif bg-opacity-10 backdrop-blur-xl backdrop-filter rounded-lg mx-auto ">
                            <h1 className="text-6xl h-full w-full flex justify-center items-center">CONTACT</h1>
                            <div className="w-full h-full flex flex-col justify-center">
                                <a href="https://www.linkedin.com/in/riccardo-540404251/" className="w-full h-24 flex justify-center items-center">
                                    <img src="linkedin.png" className="w-24 h-24 rounded-md object-fill" alt="" />
                                    <h1 className="w-full text-4xl font-medium">Riccardo .</h1>
                                </a>
                                <a href="https://www.instagram.com/r.i.c.c.a.r.d.o_4/" className="w-full h-24 flex justify-center items-center">
                                    <img src="instagram.png" className="w-24 h-24 p-1 rounded-md object-fill " alt="" />
                                    <h1 className="w-full text-4xl font-medium">r.i.c.c.a.r.d.o_4</h1>
                                </a>
                                <a href="mailto:riccardo8902@gmail.com" className="w-full h-24  flex justify-center items-center">
                                    <img src="mail.png" className="w-24 h-24 p-3 rounded-md object-fill " alt="" />
                                    <h1 className="w-full text-4xl font-medium">riccardo8902@gmail.com</h1>
                                </a>
                                <a href="https://github.com/Shirloin" className="w-full h-24 flex justify-center items-center">
                                    <img src="github.png" className="w-24 h-24 p-2 rounded-md object-fill " alt="" />
                                    <h1 className="w-full text-4xl font-medium">Shirloin</h1>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Contact;