import Go from "../logos/Go";
import Graphql from "../logos/Graphql";
import Postgres from "../logos/Postgres";
import React from "../logos/React";

export default function Web() {
    return (
        <>
            <img src="/web_img.png" className="absolute w-full h-full object-fill rounded-lg group-hover:brightness-40 transition duration-300 transform" alt="" />
            <div className="hidden absolute bottom-0 right-0 p-5 gap-6 group-hover:flex">
                <Go />
                <Graphql />
                <Postgres />
                <React />
            </div>
            <div className="absolute hidden whitespace-pre-line bottom-0 left-0 p-5 gap-6 font-serif text-2xl text-left text-white w-2/5 group-hover:flex">
                Social media platform using GO, Graphql, Postgresql, and React to connect people and allow them to interact with friends, family, and acquaintances online.
            </div>
            <div className="absolute hidden inset-0 items-center justify-center font-serif text-8xl text-white group-hover:flex">
                faREbook
            </div>
        </>
    )
}