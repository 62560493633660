import { forwardRef } from "react";

const Home = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div className="scroll-snap-align-start z-10" ref={ref}>
            <div className="pt-20 first-letter:relative flex-grow min-w-screen min-h-screen box-border flex text-white">
                <div className="p-10 w-full min-h-full flex justify-center items-center gap-10">
                    <div className="h-full w-1/3 flex justify-center items-center">
                        <div className="h-96 w-96 glass flex justify-end rounded-full">
                            <img src="/profile-nobg.png" className="w-full h-full rounded-full object-cover" alt="" />
                        </div>
                    </div>
                    <div className="h-full w-2/3 flex justify-center items-center bg-blur-xl shadow-white-box bg-white text-white bg-opacity-10 backdrop-blur-sm backdrop-filter rounded-lg p-4 mx-auto transition-transform transform hover:scale-105 hover:shadow-white">
                        <div className="p-4 max-w-xl text-xl">
                            <p className="">Hello, I'm</p>
                            <p className="text-6xl font-semibold mb-2">Riccardo</p>
                            <div className="w-fit">
                                <p className="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-6 text-white font-bold">Welcome to My personal website</p>
                            </div>
                            <p>A Junior Laboratory Assistant at Binus University. I am able to manage time during projects and able to work in a team. I am interested in the technology industry and want to gain experience and bring a positive impact to the company and others.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
});
export default Home;

