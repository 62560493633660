import { forwardRef, useState } from 'react'
import React from '../logos/React'
import Go from '../logos/Go'
import NextJs from '../logos/NextJs'
import Firebase from '../logos/Firebase'
import Postgres from '../logos/Postgres'
import CSharp from '../logos/CSharp'
import Java from '../logos/Java'
import Graphql from '../logos/Graphql'
import TypeScript from '../logos/TypeScript'
import Unity from '../logos/Unity'
import Android from '../logos/Android'
import MySQL from '../logos/MySQL'
import Laravel from '../logos/Laravel'
import Php from '../logos/Php'
import Html from '../logos/Html'
import Css from '../logos/Css'
import Js from '../logos/Js'
import Tailwind from '../logos/Tailwind'
import Sqlite from '../logos/Sqlite'
import C from '../logos/C'
import Github from '../logos/Github'
import Kotlin from '../logos/Kotlin'
import { FaHtml5 } from 'react-icons/fa'
const Skills = forwardRef<HTMLDivElement>((props, ref) => {

    const [num, setNum] = useState(0)

    return (
        <>
            <div className="scroll-snap-align-start" ref={ref}>
                <div className="pt-20 first-letter:relative flex-grow min-w-screen min-h-screen box-border flex text-white ">
                    <div className="p-10 box-border w-full min-h-full flex justify-center items-center gap-10">
                        <div className="h-full w-2/3 flex flex-col justify-center bg-blur-xl shadow-white-box bg-white text-white font-mono bg-opacity-10 backdrop-blur-xl backdrop-filter rounded-lg mx-auto ">
                            <div className="w-full h-1/6 p-4 box-border">
                                <ul className="relative flex justify-between items-center w-full h-full gap-4">
                                    <li
                                        className={`z-10 h-full w-full rounded-lg flex items-center justify-center cursor-pointer hover:bg-white hover:bg-opacity-10 ${num === 0 ? ('bg-white text-black') : ''}`}
                                        onClick={() => { setNum(0) }}
                                    >
                                        FrontEnd
                                    </li>
                                    <li
                                        className={`z-10 h-full w-full rounded-lg flex items-center justify-center cursor-pointer hover:bg-white hover:bg-opacity-10 ${num === 1 ? ('bg-white text-black') : ''}`}
                                        onClick={() => { setNum(1) }}
                                    >
                                        BackEnd
                                    </li>
                                    <li
                                        className={`z-10 h-full w-full rounded-lg flex items-center justify-center cursor-pointer hover:bg-white hover:bg-opacity-10 ${num === 2 ? ('bg-white text-black') : ''}`}
                                        onClick={() => { setNum(2) }}
                                    >
                                        Database
                                    </li>
                                    <li
                                        className={`z-10 h-full w-full rounded-lg flex items-center justify-center cursor-pointer hover:bg-white hover:bg-opacity-10 ${num === 3 ? ('bg-white text-black') : ''}`}
                                        onClick={() => { setNum(3) }}
                                    >
                                        Other
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full h-full p-4 box-border flex justify-center">
                                {
                                    num === 0 ? (
                                        <FrontEnd />
                                    ) : null
                                }
                                {
                                    num === 1 ? (
                                        <BackEnd />
                                    ) : null
                                }
                                {
                                    num === 2 ? (
                                        <Database />
                                    ) : null
                                }
                                {
                                    num === 3 ? (
                                        <Other />
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
});


function FrontEnd() {
        return (
            <>
                <div className='w-full flex flex-wrap rounded-xl gap-20 justify-center'>
                    <Html />
                    <Css />
                    <Js />
                    <React />
                    <Tailwind />
                </div>
            </>
        )
    }

function BackEnd() {
        return (
            <>
                <div className='w-full flex flex-wrap rounded-xl gap-20 justify-center'>
                    <Go />
                    <NextJs />
                    <Laravel />
                    <Php />
                </div>
            </>
        )
    }

function Database() {
        return (
            <>
                <div className='w-full flex flex-wrap rounded-xl gap-20 justify-center'>
                    <Firebase />
                    <Postgres />
                    <MySQL />
                    <Sqlite />
                </div>
            </>
        )
    }

function Other() {
        return (
            <>
                <div className='w-full flex flex-wrap rounded-xl gap-20 justify-center'>
                    <C />
                    <CSharp />
                    <Java />
                    <Graphql />
                    <TypeScript />
                    <Unity />
                    <Android />
                    <Kotlin />
                    <Github />
                </div>
            </>
        )
    }

export default Skills;