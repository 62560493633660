import { forwardRef } from "react";
import ProjectCards from "../cards/ProjectCards";

const Projects = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <>
            <div className="scroll-snap-align-start" ref={ref}>

                <div className="pt-20 relative w-full min-h-screen flex text-white box-border ">
                    {/* <h1 className="text-5xl">Projects</h1>
                <hr className="border-solid border-white border-2 w-5/6" /> */}
                    <div className="p-10 w-full min-h-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1    lg:grid-cols-2 xl:grid-cols-3 text-white font-serif gap-10">
                        <ProjectCards type={"Game"} >
                            <img src="/game_img.png" className="w-full h-full  rounded-lg object-fill" alt="" />
                        </ProjectCards>
                        <ProjectCards type={"Desktop"}>
                            <img src="/desktop_img.png" className="w-full h-full rounded-lg object-fill" alt="" />
                        </ProjectCards>
                        <ProjectCards type={"Web"}>
                            <img src="/web_img.png" className="w-full h-full rounded-lg object-fill" alt="" />
                        </ProjectCards>
                        <ProjectCards type={"Music"}>
                            <img src="/musicx.png" className="w-full h-full rounded-lg object-fill" alt="" />
                        </ProjectCards>
                        <ProjectCards type={"Android"}>
                            <img src="/android.png" className="w-full h-full rounded-lg object-contain" alt="" />
                        </ProjectCards>
                    </div>
                </div>
            </div>
        </>
    )
});
export default Projects;