/* eslint-disable react/jsx-no-undef */
import { FaRegSun } from 'react-icons/fa'


export default function Navbar({ homeRef, skillsRef, projectsRef, contactRef }: any) {
    const scrollToSection = (ref:any) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <div className="fixed top-0 z-20 navbar bg-one border-solid border-b-2 border-white text-white">
                <div className="navbar-start">
                    <h1 className="btn btn-ghost normal-case text-3xl font-mono">Riccardo</h1>
                </div>
                <div className="navbar-center">
                    <ul className="menu menu-horizontal px-1 text-xl flex items-center">
                        <li onClick={() => scrollToSection(homeRef)}><h1>Home</h1></li>
                        <li onClick={() => scrollToSection(skillsRef)}><h1>Skills</h1></li>
                        <li onClick={() => scrollToSection(projectsRef)}><h1>Projects</h1></li>
                        <li onClick={() => scrollToSection(contactRef)}><h1>Contact</h1></li>
                        {/* <li><h1><FaRegSun /></h1></li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}