import React, { useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';

function App() {
  const homeRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      <div className='fixed w-screen h-screen bg-one'>
      </div>
      <div className='min-h-screen relative z-20 w-full flex flex-col '>
        <Navbar homeRef={homeRef}
          skillsRef={skillsRef}
          projectsRef={projectsRef}
          contactRef={contactRef} />
        <div className="scroll-snap-container overflow-y-scroll h-screen scroll-snap-type-y proximity">
          <Home ref={homeRef} />
          <Skills ref={skillsRef} />
          <Projects ref={projectsRef} />
          <Contact ref={contactRef} />
        </div>
      </div>
    </>
  );
}

export default App;
