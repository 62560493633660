import Firebase from "../logos/Firebase";
import NextJs from "../logos/NextJs";
import Tailwind from "../logos/Tailwind";

export default function Desktop() {
    return (
        <>
            <img src="/desktop_img.png" className="absolute w-full h-full object-fill rounded-lg group-hover:brightness-40 transition duration-300 transform" alt="" />
            <div className="hidden absolute bottom-0 right-0 p-5 gap-6 group-hover:flex">
                <NextJs />
                <Firebase />
                <Tailwind/>
            </div>
            <div className="absolute hidden whitespace-pre-line bottom-0 left-0 p-5 gap-6 font-serif text-2xl text-left text-white w-2/5 group-hover:flex">
                Desktop application using NextJs and Firebase that is used by doctors and hospital staffs to help them track and structure their tasks.
            </div>
            <div className="absolute hidden inset-0 items-center justify-center font-serif text-8xl text-white group-hover:flex">
                siLVoam hospital
            </div>
        </>
    )
}