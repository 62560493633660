import Go from "../logos/Go";
import Graphql from "../logos/Graphql";
import React from "../logos/React";
import Tailwind from "../logos/Tailwind";

export default function Music() {
    return (
        <>
            <img src="/musicx.png" className="absolute w-full h-full object-fill rounded-lg group-hover:brightness-25 transition duration-300 transform" alt="" />
            <div className="hidden absolute bottom-0 right-0 p-5 gap-6 group-hover:flex">
                <Go />
                <Graphql />
                <React />
                <Tailwind/>
            </div>
            <div className="absolute hidden whitespace-pre-line bottom-0 left-0 p-5 gap-6 font-serif text-2xl text-left text-white  w-2/5 group-hover:flex">
                Music Player using GO, Graphql, and React to play music.
            </div>
            <div className="absolute hidden inset-0 items-center justify-center font-serif text-8xl text-white group-hover:flex">
                musiCX
            </div>
        </>
    )
}