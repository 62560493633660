import React from "../logos/React";
import Game from "../projects/Game";
import Desktop from "../projects/Desktop";
import Web from "../projects/Web";
import Music from "../projects/Music";
import HighLow from "../projects/HighLow";

interface Props {
    type: String;
    handleClick: () => void;
}
export default function Modal({ type, handleClick }: Props) {
    return (
        <>
            <div className="fixed inset-0 z-20 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center p-12" onClick={handleClick}>
                <div className="relative h-full w-full flex justify-center items-center bg-blur-xl shadow-white-box bg-black text-white bg-opacity-60 backdrop-blur-sm backdrop-filter rounded-lg mx-auto hover:shadow-white cursor-pointer group" >
                    {
                        type === "Game" ? (
                            <Game/>
                        ) : null
                    }
                    {
                        type === "Desktop" ? (
                            <Desktop/>
                        ) : null
                    }
                    {
                        type === "Web" ? (
                            <Web/>
                        ) : null
                    }
                    {
                        type === "Music" ? (
                            <Music/>
                        ):null
                    }
                    {
                        type === "Android" ? (
                            <HighLow/>
                        ):null
                    }
                </div>
            </div>
        </>
    )
}