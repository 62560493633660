import { ReactNode, useState } from "react"
import Modal from "../modal/Modal"

interface Props {
    type: String;
    children: ReactNode
}
export default function ProjectCards({ type, children }: Props) {

    const [isOpen, setIsOpen] = useState(false)

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <div className="h-56 w-96 flex justify-center items-center bg-blur-lg shadow-white-box bg-white text-white bg-opacity-10 backdrop-blur-lg backdrop-filter rounded-lg mx-auto transition-transform transform hover:scale-105 hover:shadow-white cursor-pointer" onClick={handleClick}>
                {children}
            </div>
            {
                isOpen && (
                    <Modal type={type} handleClick={handleClick}/>
                )
            }
        </>
    )
}